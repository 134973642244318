/** @jsx jsx */
import { jsx, Container, Box, Grid, Flex } from 'theme-ui'
import { FC } from 'react'
import { map, path, pipe, prop } from 'ramda'
import { object, string } from 'yup'
import AccountLayout from '~/components/account-layout'
import { useCustomerContext } from '~/context/customer-context'
import { space } from '~/gatsby-plugin-theme-ui'
import { CustomerOrder, CustomerType } from '~/@types/models'
import OrdersList from '~/components/orders-list'
import urls from '~/common/urls'
import useMediaQuery from '~/hooks/use-media-query'
import Mobile from '~/components/orders-list/mobile'
import {
  DashboardAddress,
  DashboardGrid,
  DashboardPageTitle,
  DashboardSectionHeader,
  DashboardSectionWrapper,
  DashboardText,
} from '~/components/account-layout/components/sections'
import { formatPhoneNumber } from '~/components/account-layout/utils/phone'
import { DashboardProjectGrid } from '~/components/account-layout/components/projectGrid'
import { MarketingArea } from '~/components/account-layout/components/marketingArea'
import { CustomerSegment } from '~/components/account-layout/components/customerTag'
import { InforArea } from '~/components/account-layout/components/infoArea'
import { DashboardSflGrid } from '~/components/account-layout/components/saveForLaterGrid'

type FormValues = {
  firstName: string
  lastName: string
  email: string
}

const schema = object().shape({
  firstName: string().required(),
  lastName: string().required(),
  email: string().email().required(),
})

type Props = {
  initialValues?: FormValues
}

const mapAddresses = (customer: CustomerType) => {
  const defaultAddressId = path(['defaultAddress', 'id'], customer)
  return customer.addresses.edges.map(edge => ({
    ...edge.node,
    isDefault: edge.node.id === defaultAddressId,
  }))
}

const mapOrders = pipe<CustomerType>(path(['orders', 'edges']), map(prop('node')))

const AccountDashboard: FC<Props> = () => {
  const { customer } = useCustomerContext()

  const customerInfo = customer as CustomerType
  const orders = mapOrders(customer) as CustomerOrder[]
  orders.sort((a, b) => parseInt(b.orderNumber) - parseInt(a.orderNumber))
  const addresses = mapAddresses(customer as CustomerType)
  const buttonSectionLabel = 'View all'

  const isMobile = useMediaQuery({
    query: breakpoints => `(max-width: ${breakpoints[1]})`,
  })

  return (
    <Container variant="container" sx={{ padding: '0' }}>
      <Flex sx={{ flexDirection: 'column', gap: '2px' }}>
        <DashboardSectionWrapper>
          <Grid sx={{}} columns={[1, null, null, 2]} gap={space.gridGap1}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <Flex
                sx={{
                  flexDirection: 'column',
                  boxSizing: 'border-box',
                  gap: '8px',
                  flex: 1,
                }}
              >
                <Flex
                  sx={{
                    flexDirection: 'column',
                    boxSizing: 'border-box',
                    gap: '8px',
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <DashboardPageTitle
                    title={`Hello, ${customerInfo.firstName?.toUpperCase()}!`}
                    // extra={<CustomerSegment />}
                  />
                  <InforArea />
                </Flex>
                <Box sx={{ borderTop: '2px solid #fff', position: 'relative' }}>
                  <DashboardSectionHeader
                    title="PROFILE"
                    link={urls.account.accountProfile + '/edit'}
                    label="Edit"
                    align="space-between"
                  />
                  <DashboardText>
                    {customerInfo.firstName} {customerInfo.lastName}
                  </DashboardText>
                  <DashboardText>{customerInfo.email}</DashboardText>
                  <DashboardText>{formatPhoneNumber(customerInfo?.phone)}</DashboardText>

                  <Box
                    sx={{
                      position: ['initial', null, 'absolute'],
                      right: '0',
                      bottom: '0',
                      mt: ['12px', null, '0'],
                      textAlign: '-webkit-right',
                    }}
                  >
                    <CustomerSegment />
                  </Box>
                </Box>
              </Flex>
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid #E0E0E0',
              }}
            >
              <MarketingArea />
            </Box>
          </Grid>
        </DashboardSectionWrapper>
        <DashboardSectionWrapper>
          <Box>
            <DashboardSectionHeader
              title="ORDERS"
              align="space-between"
              link={urls.account.accountOrders}
              label={buttonSectionLabel}
            />
          </Box>
          <Box>
            {isMobile ? (
              <Mobile PageSize={3} isOverview={true} orders={orders} />
            ) : (
              <OrdersList PageSize={4} isOverview={true} orders={orders} />
            )}
          </Box>
        </DashboardSectionWrapper>
        <DashboardSectionWrapper>
          <Box>
            <DashboardSectionHeader
              title="PROJECTS"
              link={urls.account.accountPortfolios}
              label={buttonSectionLabel}
            />
          </Box>
          <DashboardProjectGrid cardProps={{ style: { padding: '8px' } }} />
        </DashboardSectionWrapper>
        <DashboardSectionWrapper>
          <Box>
            <DashboardSectionHeader
              title="SAVE FOR LATER"
              link={urls.account.accountPortfolios}
              label={buttonSectionLabel}
            />
          </Box>
          <DashboardSflGrid cardProps={{ style: { padding: '8px' } }} />
        </DashboardSectionWrapper>
        <DashboardSectionWrapper>
          <Box>
            <DashboardSectionHeader
              title="ADDRESSES"
              align="space-between"
              link={urls.account.accountAddresses}
              label={buttonSectionLabel}
            />
          </Box>
          {addresses.length === 0 ? (
            <h3 sx={{ textAlign: 'center', textTransform: 'uppercase' }}>There are no addresses</h3>
          ) : (
            <DashboardGrid mbCols={1}>
              {addresses.map((address, index) => {
                return <DashboardAddress key={index} address={address} />
              })}
            </DashboardGrid>
          )}
        </DashboardSectionWrapper>
      </Flex>
    </Container>
  )
}

export default AccountDashboard
