/** @jsx jsx */
import { jsx, Container, Box, Grid, Flex, Text, Card, Image, Button } from 'theme-ui'
import { FC, Fragment, ReactNode, useEffect, useState } from 'react'

import AspectRatio from '~/componentsV2/aspectRatio'

import { space } from '~/gatsby-plugin-theme-ui'

import dropDown from '~/images/cirra-placeholder.png'

import { Link } from 'gatsby'
import CustomMenuButton from '~/components/CustomMenuButton/customMenuButton'

export const DashboardSectionWrapper = ({ children }: { children: ReactNode }) => (
  <Box
    sx={{
      background: 'rgb(214 214 214 / 39%)',
      padding: '20px',
    }}
  >
    {children}
  </Box>
)

const DashboardLabel = ({ children }: { children: ReactNode }) => (
  <span
    sx={{
      lineHeight: '18px',
      fontFamily: 'Arial',
      fontWeight: 400,
      fontSize: '16px',
      color: '#2b2b2b',
      cursor: 'pointer',
      letterSpacing: '0.5px',
      ml: [20],
      textTransform: 'uppercase',
      '&:hover': {
        textDecoration: 'underline',
        textUnderlineOffset: '3px',
      },
    }}
  >
    {children}
  </span>
)

interface IHeaderActionsProps {
  link?: string
  label?: string
  onClick?: () => void
}

interface IHeadersProps extends IHeaderActionsProps {
  title: string
  align?: 'start' | 'space-between'
  extra?: ReactNode
}

export const DashboardHeaderAction = ({ link, label, onClick }: IHeaderActionsProps) => (
  <Fragment>
    {link && (
      <Link
        sx={{ textDecoration: 'underline', color: '#2B2B2B', textUnderlineOffset: '3px' }}
        to={link}
      >
        <DashboardLabel>{label}</DashboardLabel>
      </Link>
    )}
    {onClick && (
      <Button sx={{ all: 'unset', textDecoration: 'none' }} onClick={onClick}>
        <DashboardLabel>{label}</DashboardLabel>
      </Button>
    )}
  </Fragment>
)

export const DashboardSectionHeader = ({
  title,
  link,
  label,
  onClick,
  align = 'start',
  extra,
}: IHeadersProps) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      justifyContent: align,
    }}
  >
    <h3 sx={{ fontWeight: 600 }}>{title}</h3>
    {extra && <Box sx={{ ml: 'auto' }}>{extra}</Box>}
    <DashboardHeaderAction link={link} label={label} onClick={onClick} />
  </Box>
)

export const DashboardText = ({ children }: { children: ReactNode }) => (
  <Text
    sx={{
      fontSize: '18px',
      display: 'block',
      fontFamily: 'Arial, Times New Roman, serif',
      lineHeight: '21px',
      textAlign: 'left',
      letterSpacing: '0.5px',
      color: '#2B2B2B',
    }}
  >
    {children}
  </Text>
)

export const DashboardPageTitle = ({
  label,
  title,
  align,
  link,
  onClick,
  extra,
}: IHeadersProps) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: align,
    }}
  >
    <h1
      sx={{
        fontFamily: 'Cormorant Garamond, Times New Roman, serif',
        fontWeight: 400,
        fontSize: '2rem',
        mt: [0],
        lineHeight: '48.44px',
        color: '#2b2b2b',
        letterSpacing: '0.5px',
        mb: [0],
        marginRight: ['10px', '20px'],
      }}
    >
      {title}
    </h1>

    {extra && <Box>{extra}</Box>}
    <DashboardHeaderAction link={link} label={label} onClick={onClick} />
  </Box>
)

export const DashboardProjectCard = ({
  list,
  link,
  showMenu = false,
  style,
}: {
  list: any
  link: string
  showMenu?: boolean
  style?: React.CSSProperties
}) => (
  <Card
    sx={{
      justifySelf: 'center',
      position: 'relative',
      width: '100%',
      background: 'white',
    }}
    style={style}
  >
    <Link to={link}>
      <AspectRatio ratio={1}>
        <Image
          src={list.listcontents.length > 0 ? list.listcontents[0].iu : dropDown}
          sx={{
            width: '100%',
            height: '100%',
            alignContent: 'center',
            background: '#f8f8f8',
            objectFit:
              (!!list.listcontents[0] && list.listcontents[0].dt.toLowerCase().includes('rug')) ||
              list.listcontents.length === 0
                ? 'cover'
                : 'contain',
          }}
        />
      </AspectRatio>
    </Link>
    {showMenu && (
      <Box>
        <CustomMenuButton
          type={false}
          listId={list.lid}
          listProd={list.listcontents}
          listName={list.lname}
        />
      </Box>
    )}
    <Grid
      columns={[1]}
      gap={space.gridGap3}
      sx={{
        flexDirection: 'column',
        boxSizing: 'border-box',
        marginTop: '1em',
        marginBottom: '1em',
      }}
    >
      <Box>
        <Link
          to={link}
          sx={{
            textDecoration: 'none',
            ':hover': {
              textDecoration: 'underline',
            },
          }}
        >
          <Text
            sx={{
              fontFamily: 'Cormorant Garamond',
              fontSize: '24px',
              fontWeight: 400,
              lineHeight: '19px',
              letterSpacing: '0em',
              color: '#2b2b2b',
              textTransform: 'uppercase',
            }}
          >
            {list.lname === 'default' ? 'my favorites' : list.lname}
          </Text>
        </Link>
      </Box>
      {list.listcontents.length} {list.listcontents.length === 1 ? 'item' : 'items'}
    </Grid>
  </Card>
)

export const DashboardTag = ({
  children,
  style,
  variant = 'primary',
}: {
  children: ReactNode
  style?: React.CSSProperties
  variant?: 'primary' | 'secondary'
}) => (
  <Box
    as={Text}
    sx={{
      fontFamily: 'Helvetica, Times New Roman, serif',
      height: '30px',
      padding: '0 10px',
      display: 'flex',
      alignItems: 'center',
      textTransform: 'uppercase',
      color: variant === 'primary' ? '#FFFFFF' : '#292929',
      width: 'fit-content',
      fontWeight: 400,
      fontSize: [2],
      background: variant === 'primary' ? '#585858' : 'rgba(4, 24, 38, 0.12)',
      textAlign: 'center',
      position: 'relative',
    }}
    style={style}
  >
    {children}
  </Box>
)

export const DashboardAddress = ({ address }: { address: any }) => (
  <Card
    key={address.id}
    sx={{
      border: 'none',
      position: 'relative',
      '&:not(:first-child)': {
        '::before': {
          content: '""',
          position: 'absolute',
          top: '0',
          left: '-16px',
          width: '1px',
          height: '100%',
          background: '#C8C8C8',
        },
      },
    }}
  >
    <Flex
      sx={{
        flexDirection: 'column',
        boxSizing: 'border-box',
        gap: '8px',
      }}
    >
      <DashboardText>
        {address.firstName} {address.lastName}
      </DashboardText>
      <DashboardText>{address.address1}</DashboardText>
      {address.address2 && <DashboardText>{address.address2}</DashboardText>}
      <DashboardText>
        {address.city}, {address.province} {address.zip}
      </DashboardText>
      <DashboardText>{address.country}</DashboardText>
      <DashboardText>
        {!!address.phone ? '' : ''} {address.phone}
      </DashboardText>
      {address.isDefault && (
        <DashboardTag variant="secondary" style={{ marginTop: '16px' }}>
          DEFAULT
        </DashboardTag>
      )}
    </Flex>
  </Card>
)

export const DashboardGrid = ({
  children,
  mbCols = 2,
}: {
  children: ReactNode
  mbCols?: number
}) => (
  <Grid columns={[mbCols, null, null, 3]} sx={{}} gap={space.gridGap3}>
    {children}
  </Grid>
)
