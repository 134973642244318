import React from 'react'

import { useIdentifierModal } from '~/context/identifier-modal'
import { DashboardTag } from './sections'

export const CustomerSegment = () => {
  const { customerSegment } = useIdentifierModal()

  return <DashboardTag variant="secondary">{customerSegment}</DashboardTag>
}
