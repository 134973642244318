import React, { useMemo } from 'react'
import { Link } from 'gatsby'
import { BlocksContent } from '@strapi/blocks-react-renderer'

import {
  CardWrapper,
  CardBackground,
  CardDescription,
  CardTitle,
  CardContainer,
  CardContent,
  TOverlay,
} from './styles'
import BlockTextRender from '../../components/BlockTextRender'
import ResposiveImage, { IMedia } from '../../../ResposiveImage'
import ResposiveVideo from '../../../ResposiveVideo'
import { Overlay } from '../../components/PageBuilderSection/styles'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import useMediaQuery from '~/hooks/use-media-query'
import { DeviceControl } from '../../styles'
import { TDevice } from '../../types'

export interface CardSectionData {
  title: BlocksContent
  description: BlocksContent
  link: string
  desktopImage: IMedia
  desktopVimeoId: string
  mobileImage: IMedia
  mobileVimeoId: string
  textColor: string
  overlay: TOverlay
  cardHoverOverlay: TOverlay
  device: TDevice
  visibility?: string
}

export interface CardSectionProps {
  data: CardSectionData
}

const CardSection: React.FC<CardSectionProps> = props => {
  const {
    title,
    description,
    desktopImage,
    mobileImage,
    desktopVimeoId,
    mobileVimeoId,
    overlay,
    textColor,
    cardHoverOverlay,
    device,
  } = props.data

  const isMobile = useMediaQuery({
    query: breakpoints => `(max-width: ${breakpoints[2]})`,
  })

  const shouldBeRenderContent = useMemo(() => {
    return (!isMobile && !desktopVimeoId) || (isMobile && !mobileVimeoId)
  }, [isMobile])

  return (
    <DeviceControl $device={device}>
      <CardWrapper
        className={`card-section${
          (desktopVimeoId && !isMobile) || (isMobile && mobileVimeoId) ? ' video' : ''
        }`}
        overlay={overlay}
      >
        <CardContainer className="section-container" overlay={cardHoverOverlay}>
          {shouldBeRenderContent ? (
            <Overlay className={`overlay ${cardHoverOverlay ? cardHoverOverlay : ''}'`} />
          ) : null}
          <CardBackground className="card-background">
            <ResposiveImage
              desktop={desktopImage}
              mobile={mobileVimeoId ? undefined : mobileImage}
            />
            {(!!desktopVimeoId && !isMobile) || (isMobile && mobileVimeoId) ? (
              <ResposiveVideo
                desktopId={desktopVimeoId}
                mobileId={mobileVimeoId}
                muted
                loop
                playerOptions={{
                  controls: false,
                  autoplay: true,
                  playsinline: true,
                }}
              />
            ) : null}
          </CardBackground>
          {shouldBeRenderContent ? (
            <CardContent className={'card-content ' + overlay} textColor={textColor}>
              <CardTitle className="card-title">
                <BlockTextRender content={title} />
              </CardTitle>

              <CardDescription className="card-description">
                <BlockTextRender content={description} />
              </CardDescription>
            </CardContent>
          ) : null}
        </CardContainer>
      </CardWrapper>
    </DeviceControl>
  )
}

export default CardSection
